import { Button, Chip, OutlinedInput, Portal } from "@mui/material";
import { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { RightSidebar } from "../../../../job-page-components/right-sidebar.component";
import { useRefContext } from "../../../../../../context/RefContext";
import { JobItemEntityType } from "../../../../../../../../lib/supabase/supabaseTypes";
import "./styles.css";
import { ExtendedTariffEntityType } from "../../../../../../context/JobItemsContext";
import { ItemListing } from "./item-listing.component";
import { useJobStore } from "@/dentlab/src/store/Jobs";

type CategoryType = {
	label: string;
	rule: (code: string) => boolean;
};

type Categories = CategoryType[];

const DEFAULT_CATEGORIES_OLD: Categories = [
	{
		label: "Arbeitsvorbereitung",
		rule: (code: string) => code.startsWith("0") && code.length === 3,
	},
	{
		label: "Kronen/Brücken",
		rule: (code: string) => code.startsWith("1") && code.length === 3,
	},
	{
		label: "Konstruktionsteile",
		rule: (code: string) => code.startsWith("2") && code.length === 3,
	},
	{
		label: "Modellguss",
		rule: (code: string) => code.startsWith("3") && code.length === 3,
	},
	{
		label: "Lötungen",
		rule: (code: string) => code.startsWith("4") && code.length === 3,
	},
	{
		label: "Prothetik",
		rule: (code: string) => code.startsWith("5") && code.length === 3,
	},
	{
		label: "Abn. Zahnersatz",
		rule: (code: string) => code.startsWith("6") && code.length === 3,
	},
	{
		label: "Kieferortho.",
		rule: (code: string) => code.startsWith("7") && code.length === 3,
	},
	{
		label: "Reparaturen",
		rule: (code: string) => code.startsWith("8") && code.length === 3,
	},
	{
		label: "Schienungen",
		rule: (code: string) => code.startsWith("9") && code.length === 3,
	},
];

const DEFAULT_CATEGORIES_NEW: Categories = [
	{
		label: "Arbeitsvorbereitung",
		rule: (code: string) => code.startsWith("00") && code.length === 6,
	},
	{
		label: "Kronen/Brücken",
		rule: (code: string) => code.startsWith("01") && code.length === 6,
	},
	{
		label: "Konstruktionsteile",
		rule: (code: string) => code.startsWith("02") && code.length === 6,
	},
	{
		label: "Modellguss",
		rule: (code: string) => code.startsWith("03") && code.length === 6,
	},
	{
		label: "Lötungen",
		rule: (code: string) => code.startsWith("04") && code.length === 6,
	},
	{
		label: "Prothetik",
		rule: (code: string) => code.startsWith("05") && code.length === 6,
	},
	{
		label: "Abn. Zahnersatz",
		rule: (code: string) => code.startsWith("06") && code.length === 6,
	},
	{
		label: "Kieferortho.",
		rule: (code: string) => code.startsWith("07") && code.length === 6,
	},
	{
		label: "Reparaturen",
		rule: (code: string) => code.startsWith("08") && code.length === 6,
	},
	{
		label: "Schienungen",
		rule: (code: string) => code.startsWith("09") && code.length === 6,
	},
	{
		label: "Implantat",
		rule: (code: string) => code.startsWith("10") && code.length === 6,
	},
	{
		label: "Impl. Erweiterungen",
		rule: (code: string) => code.startsWith("11") && code.length === 6,
	},
];

export const JobItemsTariffsSelection: React.FC<{
	rows: ExtendedTariffEntityType[];
	onSelect: (jobItemId: string, options?: { force: boolean }) => void;
	jobItems: JobItemEntityType[];
	jobDocumentId: number;
}> = ({ rows, onSelect, jobItems, jobDocumentId }) => {
	const label = "Leistungen";

	const [visibleRows, setVisibleRows] = useState<ExtendedTariffEntityType[]>(
		[]
	);
	const { openedSidebar, openSidebar, closeSidebar } = useJobStore(
		(state) => ({
			openedSidebar: state.openedSidebar,
			openSidebar: state.openSideBar,
			closeSidebar: state.closeSidebar,
		})
	);

	const open =
		openedSidebar?.type === "tariff" &&
		openedSidebar?.documentId === jobDocumentId;

	// TODO: save these states to database
	const [categoryType, setCategoryType] = useState<"old" | "new" | "added">(
		"new"
	);
	const [selectedCategory, setSelectedCategory] = useState<
		CategoryType | undefined
	>(DEFAULT_CATEGORIES_NEW[0]);

	const handleClose = () => {
		closeSidebar();
	};

	const handleOpen = () => {
		openSidebar(jobDocumentId, "tariff");
	};

	const handleSelect = (
		position: { code_e: string },
		options?: { force: boolean }
	) => {
		const jobItemId = position.code_e;
		onSelect(jobItemId, options);
	};

	const { container } = useRefContext();

	const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (!e.target.value) {
			setVisibleRows(
				rows.filter(
					(row) =>
						selectedCategory?.rule(row.code) ||
						(categoryType === "added" && row.custom)
				)
			);
			return;
		}

		setVisibleRows(
			rows.filter(
				(row) =>
					// This makes the search case-insensitive
					row.description_de?.match(new RegExp(e.target.value, "i"))
			)
		);
	};

	const handleClick = (
		e: React.MouseEvent<HTMLDivElement>,
		row: ExtendedTariffEntityType
	) => {
		if (e.ctrlKey || e.metaKey) {
			handleSelect({ code_e: row.code_e }, { force: true });
			return;
		}
		handleSelect({ code_e: row.code_e });
	};

	useEffect(() => {
		setVisibleRows(
			rows.filter(
				(row) =>
					selectedCategory?.rule(row.code_e) ||
					(categoryType === "added" && row.custom)
			)
		);
	}, [categoryType, rows, selectedCategory]);

	return (
		<>
			<Button
				variant="outlined"
				onClick={handleOpen}
				startIcon={<SearchIcon />}
				data-testid="leistungen-button"
				size="small"
			>
				{label}
			</Button>
			{open && (
				<Portal container={container.current}>
					<RightSidebar title={label} onClose={handleClose}>
						<div
							style={{
								padding: "10px",
								borderBottom: "var(--border-sm)",
							}}
						>
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									gap: "5px",
									paddingBottom: "5px",
									alignItems: "center",
								}}
							>
								<CategoryChip
									label="Alt"
									categoryType="old"
									selected={categoryType === "old"}
									onClick={() => {
										setCategoryType("old");
										setSelectedCategory(undefined);
									}}
								/>
								<CategoryChip
									label="Neu"
									categoryType="new"
									selected={categoryType === "new"}
									onClick={() => {
										setCategoryType("new");
										setSelectedCategory(undefined);
									}}
								/>
								<CategoryChip
									label="Eigene"
									categoryType="added"
									selected={categoryType === "added"}
									onClick={() => {
										setCategoryType("added");
										setSelectedCategory(undefined);
									}}
								/>
								<OutlinedInput
									className="mx-4 h-6"
									type="text"
									style={{
										fontSize: "14px",
									}}
									onChange={handleSearch}
									placeholder="Tariffname"
								/>
							</div>
							{categoryType === "old" && (
								<CategoryGroup
									categories={DEFAULT_CATEGORIES_OLD}
									selectedCategory={selectedCategory}
									setSelectedCategory={setSelectedCategory}
								/>
							)}
							{categoryType === "new" && (
								<CategoryGroup
									categories={DEFAULT_CATEGORIES_NEW}
									selectedCategory={selectedCategory}
									setSelectedCategory={setSelectedCategory}
								/>
							)}
						</div>
						<div
							style={{
								position: "relative",
							}}
						>
							<ItemListing
								jobItems={jobItems}
								rows={visibleRows}
								handleClick={handleClick}
							/>
						</div>
					</RightSidebar>
				</Portal>
			)}
		</>
	);
};

const CategoryGroup: React.FC<{
	categories: Categories;
	selectedCategory: CategoryType | undefined;
	setSelectedCategory: (category: CategoryType) => void;
}> = ({ categories, selectedCategory, setSelectedCategory }) => {
	return (
		<div
			style={{
				display: "flex",
				flexDirection: "row",
				flexWrap: "wrap",
				gap: "5px",
			}}
		>
			{categories.map((category: CategoryType) => (
				<Chip
					data-testid="category-group"
					key={category.label}
					label={category.label}
					onClick={() => setSelectedCategory(category)}
					variant={
						JSON.stringify(selectedCategory) ===
						JSON.stringify(category)
							? "filled"
							: "outlined"
					}
					size="small"
				/>
			))}
		</div>
	);
};

const CategoryChip: React.FC<{
	label: string;
	categoryType: "old" | "new" | "added";
	selected: boolean;
	onClick: () => void;
}> = ({ label, categoryType, selected, onClick }) => {
	return (
		<Chip
			data-testid="category-chip"
			label={label}
			onClick={onClick}
			size="small"
			variant={selected ? "filled" : "outlined"}
			color="primary"
		/>
	);
};
