import {
	GuarantorEntityType,
	PatientEntityType,
} from "@/lib/supabase/supabaseTypes";
import { PdfLetterhead } from "../pdf-template-components/pdf-letterhead.component";
import { PdfLayout } from "../pdf-template-components/pdf-layout.component";
import { PdfRecipientAddress } from "../pdf-template-components/pdf-recipient-address.component";
import { PdfJobInformation } from "../pdf-template-components/pdf-job-information.component";
import { PdfTitleAndDate } from "../pdf-template-components/pdf-title-and-date.component";
import { PdfJobItemsTable } from "../pdf-template-components/pdf-job-items-table";
import { PdfTemplateType } from "../document-template.types";
import "../fonts.css";
import { CommonPDFTemplateProps } from "./types";
import { PdfFooter } from "../pdf-template-components/pdf-footer.component";

export interface LieferscheinTemplateProps extends CommonPDFTemplateProps {
	patient?: PatientEntityType;
	guarantor?: GuarantorEntityType;
}

export const LieferscheinTemplate: React.FC<LieferscheinTemplateProps> = ({
	client,
	job,
	jobDocument,
	jobItems,
	organization,
	pdfTemplateConfiguration,
	patient,
	guarantor,
	jobTotals,
	logoSize,
}) => {
	if (
		!organization ||
		!pdfTemplateConfiguration ||
		!job ||
		!jobDocument ||
		!jobItems ||
		!client
	) {
		return null;
	}
	return (
		<PdfLayout pdfTemplateConfiguration={pdfTemplateConfiguration}>
			<PdfLetterhead
				organization={organization}
				pdfTemplateConfiguration={pdfTemplateConfiguration}
				pdfType={PdfTemplateType.LIEFERSCHEIN}
				logoSize={logoSize}
			/>
			<PdfRecipientAddress
				recipient={job.guarantor_id && guarantor ? guarantor : client}
				pdfTemplateConfiguration={pdfTemplateConfiguration}
				template={PdfTemplateType.LIEFERSCHEIN}
			/>
			<div
				aria-label="Dokumenteninhalt"
				style={{
					paddingTop: "5mm",
					paddingLeft: "5mm",
					paddingRight: "10mm",
				}}
			>
				<PdfTitleAndDate
					title={jobDocument?.title ?? "Lieferschein"}
					date={jobDocument?.date}
				/>
				<div
					aria-label="Zusatzinformationen"
					style={{
						paddingTop: "5mm",
						fontSize: "10pt",
					}}
				>
					<PdfJobInformation
						pdfTemplateType={PdfTemplateType.LIEFERSCHEIN}
						client={client}
						job={job}
						jobItems={jobItems}
						patient={patient}
					/>
				</div>
				<div
					style={{
						paddingTop: "5mm",
						fontSize: "10pt",
					}}
				>
					{jobItems && job && (
						<PdfJobItemsTable
							jobTotals={jobTotals}
							jobDocument={jobDocument}
							jobItems={jobItems}
							organization={organization}
							showDrTax={true}
							pdfTemplateConfiguration={pdfTemplateConfiguration}
						/>
					)}
				</div>
				{jobTotals.subectToTaxAmount.value > 0 && (
					<div
						aria-label="Steuerpflichtiger Betrag"
						style={{
							paddingTop: "5mm",
							fontSize: "10pt",
						}}
					>
						{`* Der steuerpflichtige Betrag ergibt CHF ${jobTotals.subectToTaxAmount.text}, steuerfrei sind CHF ${jobTotals.exemptFromTaxAmount.text}.`}
					</div>
				)}
				<PdfFooter
					templateType={PdfTemplateType.LIEFERSCHEIN}
					pdfTemplateConfiguration={pdfTemplateConfiguration}
				/>
			</div>
		</PdfLayout>
	);
};
