import {
	Button,
	IconButton,
	Slider,
	ToggleButton,
	ToggleButtonGroup,
} from "@mui/material";
import {
	PdfTemplateConfiguration,
	usePdfTemplateConfigurationContext,
} from "../../context/PdfTemplateConfigurationContext";
import { useEffect, useState } from "react";
import { showNotification } from "../../store/Central/selectors";
import { compressImage } from "@/lib/utils/compress-image";
import { v4 as uuidv4 } from "uuid";
import { Logger } from "@/lib/logger/Logger";
import SaveIcon from "@mui/icons-material/Save";
import { RestartAlt } from "@mui/icons-material";
import clsx from "clsx";
import { supabase } from "@/lib/supabase";
import { StorageBucketsEnum } from "../../types/enums";
import { useCentralStore } from "../../store/Central";
import { Alignment } from "../../pdf-templates/pdf-templates/types";

export const PdfTemplateLogo = ({
	pdfTemplateConfiguration,
	logoSize,
	setLogoSize,
}: {
	pdfTemplateConfiguration: PdfTemplateConfiguration | undefined;
	logoSize: number;
	setLogoSize: (size: number) => void;
}) => {
	const imagePath = pdfTemplateConfiguration?.general.logoPath;
	const [logoPic, setLogoPic] = useState<string | null>(null);
	const { organizationId, logoUrl, getLogoUrl, setLogoPath, clearLogoUrl } =
		useCentralStore((state) => ({
			organizationId: state.organization?.id,
			logoUrl: state.logoUrl,
			getLogoUrl: state.getLogoUrl,
			setLogoPath: state.setLogoPath,
			logoPath: state.logoPath,
			clearLogoUrl: state.clearLogoUrl,
		}));

	const { updatePdfTemplateConfiguration } =
		usePdfTemplateConfigurationContext();

	const [logoPicPath, setLogoPicPath] = useState<string | null>(null);
	const [uploading, setUploading] = useState(false);

	useEffect(() => {
		if (imagePath) {
			setLogoPath(imagePath);
		}
		getLogoUrl();
	}, [imagePath, organizationId]);

	const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		setUploading(true);
		if (!e.target.files || e.target.files.length <= 0) return;

		const file = e.target.files[0];

		if (file.size > 5 * 1024 * 1024) {
			showNotification({
				message: "Bitte wählen Sie eine Datei kleiner als 5MB",
				type: "error",
			});

			return;
		}

		const compressedFile = await compressImage(file, 0.9);

		const reader = new FileReader();

		reader.onloadend = () => {
			clearLogoUrl();
			setLogoPic(reader.result as string);
		};

		reader.readAsDataURL(file);

		const imageName = `logos/${uuidv4()}/${file.name}`;

		const { error } = await supabase.storage
			.from(StorageBucketsEnum.ORG_V1)
			.upload(`${organizationId}/${imageName}`, compressedFile);

		if (error) {
			Logger.error("Error uploading");
			showNotification({
				message: "Fehler beim Hochladen des Logos",
				type: "error",
			});

			return;
		}

		setLogoPicPath(imageName);
		setUploading(false);
	};
	return (
		<div className="w-full flex flex-col gap-4">
			<div className="flex flex-col items-center gap-4">
				<div
					className={clsx(
						"h-[20mm] relative  border  overflow-hidden max-w-60",
						{
							"w-full": !(logoUrl || logoPic),
						}
					)}
				>
					{uploading && (
						<div className="absolute flex items-center justify-center top-0 left-0 w-full h-full">
							<p>Hochladen...</p>
						</div>
					)}
					{(logoUrl || logoPic) && (
						<img
							style={{
								opacity: uploading ? 0.5 : 1,
							}}
							alt="logo"
							src={logoUrl ?? logoPic ?? ""}
							className=" h-full object-cover aspect-auto"
						/>
					)}
				</div>
				<input
					id="logo"
					name="logo"
					onChange={handleFileChange}
					type="file"
					className="hidden"
				/>
				<Button onClick={() => {}} disabled={uploading}>
					<label htmlFor="logo">Hochladen</label>
				</Button>
				<Slider
					onChange={(_, v) => {
						setLogoSize(v as number);
					}}
					defaultValue={18}
					aria-label="Disabled slider"
					value={logoSize}
					min={15}
					max={40}
				/>
				{logoSize}
			</div>

			<ToggleButtonGroup
				color="primary"
				value={pdfTemplateConfiguration?.general.logoAlignment}
				className="flex justify-center"
				exclusive
				onChange={(e, v) => {
					updatePdfTemplateConfiguration("general", {
						logoAlignment: v as Alignment,
					});
				}}
				aria-label="Platform"
			>
				<ToggleButton value="left">Links</ToggleButton>
				<ToggleButton value="center">Zentriert</ToggleButton>
				<ToggleButton value="right">Rechts</ToggleButton>
			</ToggleButtonGroup>

			<div className="w-full flex justify-end">
				<IconButton
					disabled={!logoPicPath}
					color="primary"
					onClick={async () => {
						updatePdfTemplateConfiguration("general", {
							logoPath: "",
							logoImgurl: "",
						});
						setLogoPicPath("");
						setLogoPic("");
					}}
				>
					<RestartAlt />
				</IconButton>
				<IconButton
					disabled={
						logoPicPath === imagePath &&
						pdfTemplateConfiguration?.general.logoSize == logoSize
					}
					color="primary"
					onClick={async () => {
						const image_src =
							((
								await supabase.storage
									.from(StorageBucketsEnum.ORG_V1)
									.createSignedUrl(
										`${organizationId}/${logoPicPath}`,
										3600,
										{
											transform: {
												quality: 60,
											},
										}
									)
							).data?.signedUrl as string) ?? "";
						await updatePdfTemplateConfiguration("general", {
							logoPath: logoPicPath ?? "",
							logoImgurl: image_src,
							logoSize,
						});
						updatePdfTemplateConfiguration("general", {});
						setTimeout(() => {
							updatePdfTemplateConfiguration("general", {
								logoImgurl: "",
							});
						}, 3599 * 1000);
					}}
				>
					<SaveIcon />
				</IconButton>
			</div>
		</div>
	);
};
