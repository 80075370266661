import {
	Button,
	Checkbox,
	FormControlLabel,
	FormGroup,
	InputLabel,
	ToggleButton,
	ToggleButtonGroup,
} from "@mui/material";
import { usePdfTemplateConfigurationContext } from "../../context/PdfTemplateConfigurationContext";
import { Alignment } from "../../pdf-templates/pdf-templates/types";
import { PdfTemplateType } from "../../pdf-templates/document-template.types";
import RichTextEditor from "@mantine/rte";
import { compressImage } from "@/lib/utils/compress-image";
import { supabase } from "@/lib/supabase";
import { StorageBucketsEnum } from "../../types/enums";
import { v4 as uuidv4 } from "uuid";
import { useCentralStore } from "../../store/Central";
import {
	AccordionItem,
	AccordionTrigger,
	AccordionContent,
} from "../../components/ui/accordion";

export const PDFTemplateConfigurationSettings = ({
	template,
}: {
	template: PdfTemplateType | "general";
}) => {
	const { updatePdfTemplateConfiguration, pdfTemplateConfiguration } =
		usePdfTemplateConfigurationContext();

	const config = pdfTemplateConfiguration?.[template];
	const { organization } = useCentralStore((state) => ({
		organization: state.organization,
	}));

	if (!config) return;

	const upload = async (file: File) => {
		const compressedFile = await compressImage(file, 35);
		const path = `${organization?.id}/${uuidv4()}/${file.name}`;
		const { data } = await supabase.storage
			.from(StorageBucketsEnum.ORG_V1)
			.upload(path, compressedFile);

		if (data?.path) {
			const { data: urlData } = await supabase.storage
				.from(StorageBucketsEnum.ORG_V1)
				.createSignedUrl(data.path, 3600, {});
			updatePdfTemplateConfiguration("general", {
				customIconPath: data.path,
				customImgUrl: urlData?.signedUrl,
			});
			// Clear the url when it expires
			setTimeout(() => {
				updatePdfTemplateConfiguration("general", {
					customImgUrl: "",
				});
			}, 3599 * 1000);
		}
	};
	return (
		<>
			{" "}
			<AccordionItem value="item-1">
				<AccordionTrigger>Kopfzeile und Laboradresse</AccordionTrigger>
				<AccordionContent>
					<div className="flex border-b-gray-100 border-b mt-4  flex-col gap-4">
						<div className="flex flex-col gap-2">
							<InputLabel id="Address alignment">
								Adressausrichtung
							</InputLabel>

							<ToggleButtonGroup
								color="primary"
								value={
									config.oneLiner
										? "one-liner"
										: config.addressAlignment
								}
								exclusive
								onChange={(e, v) => {
									if (v == "one-liner") {
										updatePdfTemplateConfiguration(
											template,
											{
												oneLiner: true,
											}
										);
										return;
									}
									updatePdfTemplateConfiguration(template, {
										addressAlignment: v as Alignment,
										oneLiner: false,
									});
								}}
								aria-label="Platform"
							>
								<ToggleButton value="left">Links</ToggleButton>
								<ToggleButton value="right">
									Rechts
								</ToggleButton>
								<ToggleButton value="one-liner">
									Einzeiler
								</ToggleButton>
							</ToggleButtonGroup>
						</div>

						<div className="flex flex-col gap-2">
							<InputLabel id="Address alignment">
								Adresse Text
							</InputLabel>

							<ToggleButtonGroup
								color="primary"
								value={config.addressRtfEnabled}
								exclusive
								onChange={(e, v) => {
									updatePdfTemplateConfiguration(template, {
										addressRtfEnabled: v as boolean,
									});
								}}
								aria-label="Platform"
							>
								<ToggleButton value={false}>
									Standard
								</ToggleButton>
								<ToggleButton value={true}>
									Individuell
								</ToggleButton>
							</ToggleButtonGroup>
							<div>
								<RichTextEditor
									style={{
										width: "100%",
										maxWidth: "350px",
										marginBottom: "24px",
									}}
									controls={[["italic", "underline", "bold"]]}
									value={config.addressRtfText}
									onChange={(e) => {
										if (!config.addressRtfEnabled) return;
										updatePdfTemplateConfiguration(
											template,
											{
												addressRtfText: e,
											}
										);
									}}
								/>
							</div>

							<FormControlLabel
								className="mb-1"
								control={
									<Checkbox
										checked={config.showBorder}
										onChange={(e) => {
											updatePdfTemplateConfiguration(
												template,
												{
													showBorder:
														e.target.checked,
												}
											);
										}}
									/>
								}
								label="Trennstrich"
							/>
						</div>
					</div>
				</AccordionContent>
			</AccordionItem>
			<AccordionItem value="item-2">
				<AccordionTrigger>Empfängeradresse </AccordionTrigger>
				<AccordionContent>
					<div className="flex flex-col border-b gap-4 mt-4 pb-4">
						<InputLabel id="Address alignment">
							Ausrichtung
						</InputLabel>

						<ToggleButtonGroup
							color="primary"
							value={config.recipientAddressAlignment}
							exclusive
							onChange={(e, v) => {
								updatePdfTemplateConfiguration(template, {
									recipientAddressAlignment: v as Alignment,
								});
								if (!config.idOneLiner) {
									const opposite = {
										left: "right",
										right: "left",
									};

									const newAlignment =
										opposite[v as "left" | "right"];

									updatePdfTemplateConfiguration(template, {
										idAlignment: newAlignment as
											| "left"
											| "right",
									});
								}
							}}
							aria-label="Platform"
						>
							<ToggleButton value="left">Links</ToggleButton>
							<ToggleButton value="right">Rechts</ToggleButton>
						</ToggleButtonGroup>
					</div>
				</AccordionContent>
			</AccordionItem>
			<AccordionItem value="item-3">
				<AccordionTrigger>Identifikationsnummern</AccordionTrigger>
				<AccordionContent>
					<div className="flex border-b-gray-100 border-b mt-4  flex-col gap-4">
						<div className="flex flex-col border-b gap-4 mt-4 pb-4">
							<InputLabel id="Address alignment">
								Platzierung
							</InputLabel>

							<ToggleButtonGroup
								color="primary"
								value={config.idOneLiner}
								exclusive
								onChange={(e, v) => {
									let newAlignment;
									if (!v) {
										if (
											config.idAlignment ==
											config.recipientAddressAlignment
										) {
											const opposite = {
												left: "right",
												right: "left",
											};

											newAlignment =
												opposite[
													config.recipientAddressAlignment as
														| "left"
														| "right"
												];
										}
									}
									updatePdfTemplateConfiguration(template, {
										idOneLiner: v as boolean,
										...(newAlignment
											? {
													idAlignment: newAlignment,
												}
											: {}),
									});
								}}
								aria-label="Platform"
							>
								<ToggleButton value={true}>
									Kopfzeile
								</ToggleButton>
								<ToggleButton value={false}>
									Adresshöhe
								</ToggleButton>
							</ToggleButtonGroup>

							<InputLabel id="Address alignment">Text</InputLabel>

							<ToggleButtonGroup
								color="primary"
								value={config.idRtfEnabled}
								exclusive
								onChange={(e, v) => {
									updatePdfTemplateConfiguration(template, {
										idRtfEnabled: v,
									});
								}}
								aria-label="Platform"
							>
								<ToggleButton value={false}>
									Standard
								</ToggleButton>
								<ToggleButton value={true}>
									Individuell
								</ToggleButton>
							</ToggleButtonGroup>

							<div>
								<RichTextEditor
									style={{
										width: "100%",
										maxWidth: "350px",
										marginBottom: "24px",
									}}
									controls={[["italic", "underline", "bold"]]}
									value={config.idRtfText}
									onChange={(e) => {
										if (!config.idRtfEnabled) return;
										updatePdfTemplateConfiguration(
											template,
											{
												idRtfText: e,
											}
										);
									}}
								/>
							</div>
						</div>
					</div>
				</AccordionContent>
			</AccordionItem>
			<AccordionItem value="item-4">
				<AccordionTrigger>Mitgliedschaften</AccordionTrigger>
				<AccordionContent>
					<div className="flex flex-col border-b gap-4 mt-4 pb-4">
						<div>Logos</div>

						<div className="flex">
							<FormGroup>
								<FormControlLabel
									control={
										<Checkbox
											checked={config.showVzlsMembership}
											onChange={(e) => {
												updatePdfTemplateConfiguration(
													template,
													{
														showVzlsMembership:
															e.target.checked,
													}
												);
											}}
										/>
									}
									label="VZLS"
								/>
							</FormGroup>
							<FormGroup>
								<FormControlLabel
									control={
										<Checkbox
											checked={config.showSwissLabel}
											onChange={(e) => {
												updatePdfTemplateConfiguration(
													template,
													{
														showSwissLabel:
															e.target.checked,
													}
												);
											}}
										/>
									}
									label="Swiss Label"
								/>
							</FormGroup>
							<FormGroup>
								<FormControlLabel
									control={
										<Checkbox
											checked={config.showSZVLabel}
											onChange={(e) => {
												updatePdfTemplateConfiguration(
													template,
													{
														showSZVLabel:
															e.target.checked,
													}
												);
											}}
										/>
									}
									label="SZV"
								/>
							</FormGroup>
						</div>

						<Button>
							<label
								className="cursor-pointer"
								htmlFor="icon-upload"
							>
								Individuell
								<input
									type="file"
									className="hidden"
									id="icon-upload"
									onChange={(e) => {
										const file = e.target.files?.[0];

										if (!file) return;

										upload(file);
									}}
								/>
							</label>
						</Button>

						<div className="flex flex-col gap-2">
							<InputLabel id="Address alignment">
								Ausrichtung
							</InputLabel>

							<ToggleButtonGroup
								color="primary"
								value={config.organizationsImgAlignment}
								exclusive
								onChange={(e, v) => {
									updatePdfTemplateConfiguration(template, {
										organizationsImgAlignment:
											v as Alignment,
									});
								}}
								aria-label="Platform"
							>
								<ToggleButton value="left">Links</ToggleButton>
								<ToggleButton value="right">
									Rechts
								</ToggleButton>
							</ToggleButtonGroup>
						</div>
						<div className="flex flex-col gap-2">
							<InputLabel id="Address alignment">
								Platzierung
							</InputLabel>

							<ToggleButtonGroup
								color="primary"
								value={config.organizationsImgPosition}
								exclusive
								onChange={(e, v) => {
									updatePdfTemplateConfiguration(template, {
										organizationsImgPosition: v as
											| "top"
											| "bottom",
									});
								}}
								aria-label="Platform"
							>
								<ToggleButton value="top">
									Kopfzeile
								</ToggleButton>
								<ToggleButton value="bottom">
									Fusszeile
								</ToggleButton>
							</ToggleButtonGroup>
						</div>
						<div>
							<div>Text</div>
							<RichTextEditor
								style={{
									width: "100%",
									maxWidth: "350px",
									marginBottom: "24px",
								}}
								controls={[["italic", "underline", "bold"]]}
								value={config.orgImgText}
								onChange={(e) =>
									updatePdfTemplateConfiguration(template, {
										orgImgText: e,
									})
								}
							/>
						</div>
					</div>
				</AccordionContent>
			</AccordionItem>
		</>
	);
};
