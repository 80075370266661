import {
	ClientEntityType,
	GuarantorEntityType,
} from "@/lib/supabase/supabaseTypes";
import { PdfTemplateConfiguration } from "../../context/PdfTemplateConfigurationContext";
import { PdfTemplateType } from "../document-template.types";
import { getConfig } from "../../context/util";

export const PdfRecipientAddress: React.FC<{
	recipient: ClientEntityType | GuarantorEntityType;
	pdfTemplateConfiguration: PdfTemplateConfiguration | undefined;
	template?: PdfTemplateType | "general";
}> = ({ recipient, pdfTemplateConfiguration, template }) => {
	const alignment = {
		left: "start",
		center: "center",
		right: "end",
	};
	// const config = pdfTemplateConfiguration?.[template ?? "general"];
	const generalConfig = pdfTemplateConfiguration!.general;
	const pdfConfig = pdfTemplateConfiguration?.[template ?? "general"] || {};

	const config = getConfig(pdfConfig, generalConfig);

	const logoSize = generalConfig?.logoSize ?? 15;

	const topPadding = (logoSize * (logoSize - 20)) / 25;
	return (
		<div
			aria-label="Empfängeradresse"
			style={{
				height: "25mm",
				paddingTop: `${Math.max(topPadding, 18)}mm`,
				paddingBottom: "10mm",
				width: "100%",
				paddingLeft: generalConfig?.clientAddressLeft ? "6mm" : "97mm", // Linksadressierungen beginnen 26mm von links, Rechtsadressierungen 117mm von links.
				fontSize: "10pt",
				position: "relative",
				display: "flex",
				alignItems:
					alignment[config?.recipientAddressAlignment ?? "left"],
				flexDirection: "column",
			}}
		>
			<p>{recipient.title}</p>
			<p>
				{recipient.last_name} {recipient.first_name}
			</p>
			<p>{recipient.street}</p>
			<p>
				{recipient.postal_code} {recipient.city}
			</p>
		</div>
	);
};
