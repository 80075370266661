import { create } from "zustand";
import { CentralSlice, createCentralStore } from "./central.store";
import { ClientSlice, createClientStore } from "./client.store";
import { ConfigSlice, createConfigStore } from "./config.store";
import { PdfSlice, createPDFStore } from "./pdf.store";

interface CentralStoreTypes
	extends CentralSlice,
		ClientSlice,
		ConfigSlice,
		PdfSlice {}

export const useCentralStore = create<CentralStoreTypes>((...args) => ({
	...createCentralStore(...args),
	...createClientStore(...args),
	...createConfigStore(...args),
	...createPDFStore(...args),
}));
