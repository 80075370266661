import { StateCreator } from "zustand";
import { State } from "./types";
import { initialState } from "./utils";
import { StorageBucketsEnum } from "../../types/enums";
import { supabase } from "@/lib/supabase";

export interface PdfSlice extends State {
	logoPath: string | null;
	setLogoPath: (logoPath: string) => void;
	logoUrl: string | null;
	getLogoUrl: () => Promise<void>;
	clearLogoUrl: () => void;
}

export const createPDFStore: StateCreator<PdfSlice> = (set, get) => ({
	...initialState,
	logoUrl: null,
	clearLogoUrl: () => set({ logoUrl: null }),
	setLogoPath: (logoPath: string) => set({ logoPath }),
	logoPath: null,
	getLogoUrl: async () => {
		const imagePath = get().logoPath;
		const organization = get().organization;
		if (!imagePath || !organization) return;
		const imageSrc = await supabase.storage
			.from(StorageBucketsEnum.ORG_V1)
			.createSignedUrl(`${organization?.id}/${imagePath}`, 3600, {
				transform: {
					quality: 35,
				},
			});

		if (!imageSrc.data) return;

		set({
			logoUrl: imageSrc.data.signedUrl,
		});

		setTimeout(() => get().getLogoUrl(), 3599 * 1000);
	},
});
