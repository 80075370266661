import { PdfTemplateConfiguration } from "../../context/PdfTemplateConfigurationContext";
import "../fonts.css";
import { PdfTemplateType } from "../document-template.types";
import vzlsLogo from "../../assets/vzls.ico";
import swissLabelLogo from "../../assets/swisslabel.png";
import parse from "html-react-parser";
import szvLogo from "../../assets/szv.jpg";
import { getConfig } from "../../context/util";

export const PdfFooter = ({
	pdfTemplateConfiguration,
	templateType,
}: {
	pdfTemplateConfiguration: PdfTemplateConfiguration;
	templateType: PdfTemplateType;
}) => {
	const generalConfig = pdfTemplateConfiguration.general;
	const pdfConfig = pdfTemplateConfiguration?.[templateType] || {};

	const config = getConfig(pdfConfig, generalConfig);

	return (
		<div>
			<div
				aria-label="Kommentar"
				style={{
					paddingTop: "5mm",
					fontSize: "10pt",
					display: "flex",
					justifyContent: generalConfig.commentAlignment,
					textAlign: generalConfig.commentAlignment,
				}}
			>
				{parse(pdfConfig.commentText ?? "")}
			</div>
			<div
				aria-label="Fusszeile"
				style={{
					paddingTop: "5mm",
					fontSize: "10pt",
					display: "flex",
					justifyContent: generalConfig.footerAlignment,
					textAlign: generalConfig.footerAlignment,
				}}
			>
				{parse(pdfConfig.footerText)}
			</div>
			{config.organizationsImgPosition === "bottom" && (
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						gap: "2mm",
						alignItems: "center",
						width: "100%",
						justifyContent: config.organizationsImgAlignment as
							| "left"
							| "right",
						left:
							config.organizationsImgAlignment == "left"
								? `${generalConfig.marginLeft}mm`
								: "",
						right:
							config.organizationsImgAlignment == "right"
								? `${generalConfig.marginRight}mm`
								: "",
						bottom: 0,
					}}
				>
					{config.organizationsImgAlignment === "right" && (
						<p
							style={{
								fontStyle: "italic",
							}}
						>
							{parse(config.orgImgText ?? "")}
						</p>
					)}
					{config?.showVzlsMembership && (
						<>
							<img src={vzlsLogo} height={20} alt="VZLS Logo" />
						</>
					)}

					{config?.showSwissLabel && (
						<img
							src={swissLabelLogo}
							width={30}
							alt="Swiss Label Logo"
						/>
					)}

					{config.showSZVLabel && (
						<img src={szvLogo} width={30} alt="SZV Label Logo" />
					)}
					{config.customImgUrl && (
						<img
							src={config.customImgUrl}
							width={30}
							alt="Custom Image"
						/>
					)}
					{config.organizationsImgAlignment === "left" && (
						<p
							style={{
								fontStyle: "italic",
							}}
						>
							{parse(config.orgImgText ?? "")}
						</p>
					)}
				</div>
			)}
		</div>
	);
};
