import {
	GuarantorEntityType,
	PatientEntityType,
} from "@/lib/supabase/supabaseTypes";
import { PdfLetterhead } from "../pdf-template-components/pdf-letterhead.component";
import { PdfLayout } from "../pdf-template-components/pdf-layout.component";
import { PdfRecipientAddress } from "../pdf-template-components/pdf-recipient-address.component";
import "../fonts.css";
import { PdfTemplateType } from "../document-template.types";
import { PaymentSlipDataInput } from "../pdf-template-components/qr-payment-slip/qr-payment-helpers";
import { PdfInvoiceBody } from "../pdf-template-components/pdf-invoice-body.component";
import { CommonPDFTemplateProps } from "./types";
import { PdfFooter } from "../pdf-template-components/pdf-footer.component";

export interface KartenzahlungsquittungTemplateProps
	extends CommonPDFTemplateProps {
	patient?: PatientEntityType;
	guarantor?: GuarantorEntityType;
	paymentSlipData: PaymentSlipDataInput;
}

export const KartenzahlungsquittungTemplate: React.FC<
	KartenzahlungsquittungTemplateProps
> = ({
	client,
	job,
	jobDocument,
	jobItems,
	organization,
	pdfTemplateConfiguration,
	patient,
	guarantor,
	paymentSlipData,
	jobTotals,
	logoSize,
}) => {
	if (
		!organization ||
		!pdfTemplateConfiguration ||
		!job ||
		!jobDocument ||
		!jobItems ||
		!client ||
		!paymentSlipData
	) {
		return null;
	}
	return (
		<PdfLayout pdfTemplateConfiguration={pdfTemplateConfiguration}>
			<PdfLetterhead
				organization={organization}
				pdfTemplateConfiguration={pdfTemplateConfiguration}
				pdfType={PdfTemplateType.KARTENZAHLUNGSQUITTUNG}
				logoSize={logoSize}
			/>
			<PdfRecipientAddress
				recipient={job.guarantor_id && guarantor ? guarantor : client}
				pdfTemplateConfiguration={pdfTemplateConfiguration}
				template={PdfTemplateType.KOSTENVORANSCHLAG}
			/>
			<PdfInvoiceBody
				pdfTemplateConfiguration={pdfTemplateConfiguration}
				title="Quittung"
				client={client}
				job={job}
				jobDocument={jobDocument}
				jobItems={jobItems}
				patient={patient}
				paymentSlipData={paymentSlipData}
				organization={organization}
				jobTotals={jobTotals}
			/>
			<PdfFooter
				templateType={PdfTemplateType.KARTENZAHLUNGSQUITTUNG}
				pdfTemplateConfiguration={pdfTemplateConfiguration}
			/>
		</PdfLayout>
	);
};
