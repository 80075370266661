import { StateCreator } from "zustand";
import { State } from "./types";
import { formatArticle, formatTariff, initialState } from "./utils";
import { Logger } from "@/lib/logger/Logger";
import { useCentralStore } from "../Central";
import { supabase } from "@/lib/supabase";
import {
	JobItemEntityType,
	SupabaseTableEnum,
} from "@/lib/supabase/supabaseTypes";
import { produce } from "immer";
import { v4 as uuidv4 } from "uuid";
import { showNotification } from "../Central/selectors";
import {
	ExtendedArticleEntityType,
	ExtendedTariffEntityType,
} from "../../context/JobItemsContext";

export interface JobItemSlice extends State {
	fetchJobItems: (jobDocumentId: number) => Promise<void>;
	addItem: (
		code: string,
		jobDocumentId: number,
		itemType: "article" | "tariff",
		options?: { force: boolean }
	) => Promise<void>;
	addTariff: (
		code: string,
		jobDocumentId: number,
		options?: { force: boolean }
	) => Promise<void>;
	addArticle: (
		code: string,
		jobDocumentId: number,
		options?: { force: boolean }
	) => Promise<void>;
	deleteJobItems: (
		jobItemIds: string[],
		jobDocumentId: number
	) => Promise<void>;
	pasteJobItems: (
		jobItems: JobItemEntityType[],
		jobDocumentId: number
	) => Promise<void>;
	changeJobItem: (jobItem: any) => Promise<void>;
	upsertJobItems: (
		jobDocumentId: number,
		errorMessage: string
	) => Promise<void>;
	handleTimer: (
		jobDocumentId: number,
		itemType: "tariff" | "article" | "group"
	) => Promise<void>;
}

export const createJobItemStore: StateCreator<JobItemSlice> = (set, get) => ({
	...initialState,
	fetchJobItems: async (jobDocumentId: number) => {
		const { data, error } = await supabase
			.from(SupabaseTableEnum.JOB_ITEMS)
			.select(`*`)
			.eq("job_document_id", jobDocumentId)
			.order("code", { ascending: true });

		if (error) {
			showNotification({
				message: "Fehler beim Laden der Positionen",
				type: "error",
			});
			Logger.error(error);
			return;
		}

		set({
			jobItemsForDocuments: {
				...get().jobItemsForDocuments,
				[jobDocumentId]: data,
			},
		});
	},

	// Post since this is explicitly for updating the job items through the network and not the local store
	upsertJobItems: async (jobDocumentId: number, errorMessage: string) => {
		const items = get().jobItemsForDocuments[jobDocumentId].map((i) => {
			const {
				// @ts-expect-error unregistered properties
				interpr_de,
				// @ts-expect-error unregistered properties
				interpr_fr,
				// @ts-expect-error unregistered properties
				interpr_it,
				fpo_id,
				// @ts-expect-error unregistered properties
				bpo_id,
				...rest
			} = i;
			return {
				...rest,
			};
		});

		const { error } = await supabase
			.from(SupabaseTableEnum.JOB_ITEMS)
			.upsert(items, { onConflict: "id", defaultToNull: false });

		if (error) {
			showNotification({
				message: "Fehler beim Speichern der Positionen",
				type: "error",
			});
			Logger.error(error, errorMessage);
			return;
		}
	},

	addItem: async (
		code: string,
		jobDocumentId: number,
		itemType: "article" | "tariff",
		options?: { force: boolean }
	) => {
		const existingItems: number[] = [];
		get().jobItemsForDocuments[jobDocumentId]?.forEach((item, i) => {
			if (item.code === code) {
				existingItems.push(i);
			}
		});

		if (existingItems.length > 0 && !options?.force) {
			set(
				produce((state) => {
					existingItems.forEach((i) => {
						state.jobItemsForDocuments[jobDocumentId][i].quantity +=
							1;
					});
				})
			);
		} else {
			let items:
				| Record<string, ExtendedTariffEntityType>
				| Record<string, ExtendedArticleEntityType>
				| null = null;
			if (itemType == "tariff")
				items = useCentralStore.getState().tariffs;
			else if (itemType == "article")
				items = useCentralStore.getState().articles;

			if (!items) {
				showNotification({
					message:
						"Position kann nicht hinzugefügt werden. Fehlende Stammdaten.",
					type: "error",
				});
				return;
			}
			const item = items[code];
			const clientId = useCentralStore.getState().clientId;

			if (!jobDocumentId || !clientId) {
				showNotification({
					message:
						"Position kann nicht hinzugefügt werden. Fehlende Referenz.",
					type: "error",
				});
				Logger.error("Job document id or client id is not set");
				return;
			}

			const job = get().job;
			if (!job) {
				showNotification({
					message:
						"Position kann nicht hinzugefügt werden. Fehlender Auftrag.",
					type: "error",
				});
				Logger.error("Job is not set");
				return;
			}
			const jobTpTier = job.tp_tier;
			const jobTpValue = job.tp_value;
			if (jobTpTier === null || jobTpValue === null) {
				showNotification({
					message:
						"Position kann nicht hinzugefügt werden. Fehlender Taxpunktwert oder Taxpunktstufe.",
					type: "error",
				});
				Logger.error("Job document id or client id is not set");
				return;
			}

			const formattedItem =
				itemType === "tariff"
					? formatTariff(
							item as ExtendedTariffEntityType,
							jobDocumentId,
							clientId as string,
							get().job?.guarantor_id?.toString() ?? null,
							jobTpTier,
							jobTpValue
						)
					: formatArticle(
							item as ExtendedArticleEntityType,
							jobDocumentId
						);

			set(
				produce((state) => {
					if (!state.jobItemsForDocuments[jobDocumentId]) {
						state.jobItemsForDocuments[jobDocumentId] = [];
					}
					state.jobItemsForDocuments[jobDocumentId].push(
						formattedItem
					);
				})
			);
		}

		get().handleTimer(jobDocumentId, itemType);
	},
	handleTimer: async (
		jobDocumentId: number,
		itemType: "tariff" | "article" | "group"
	) => {
		const timer = get().timers[jobDocumentId];
		if (timer) clearTimeout(timer);

		const newTimer = setTimeout(async () => {
			Logger.info(`Adding ${itemType} to database`);
			get().upsertJobItems(jobDocumentId, `Error adding ${itemType}`);
			set(
				produce((state) => {
					delete state.timers[jobDocumentId];
				})
			);
		}, 3000);

		set(
			produce((state) => {
				state.timers[jobDocumentId] = newTimer;
			})
		);
	},

	addTariff: async (
		code: string,
		jobDocumentId: number,
		options?: { force: boolean }
	) => {
		get().addItem(code, jobDocumentId, "tariff", options);
	},

	addArticle: async (
		code: string,
		jobDocumentId: number,
		options?: { force: boolean }
	) => {
		get().addItem(code, jobDocumentId, "article", options);
	},

	deleteJobItems: async (jobItemIds: string[], jobDocumentId: number) => {
		const jobItemsForDocument = get().jobItemsForDocuments[jobDocumentId];
		const updatedJobItemsForDocument = jobItemsForDocument.filter(
			(item) => !jobItemIds.includes(item.id)
		);
		set(
			produce((state) => {
				state.jobItemsForDocuments[jobDocumentId] =
					updatedJobItemsForDocument;
			})
		);

		const { error } = await supabase
			.from(SupabaseTableEnum.JOB_ITEMS)
			.delete()
			.in("id", jobItemIds);

		if (error) {
			showNotification({
				message: "Fehler beim Löschen der Positionen",
				type: "error",
			});
			Logger.error(error);
			return;
		}

		showNotification({
			message: "Gelöscht",
			type: "success",
		});
	},

	pasteJobItems: async (
		jobItems: JobItemEntityType[],
		jobDocumentId: number
	) => {
		const newJobItems = jobItems.map((row) => {
			const { id, modified_at, created_at, fpo_id, ...filteredRow } = row;

			return {
				...filteredRow,
				id: uuidv4(),
				price: isNaN(filteredRow.price as number)
					? 0
					: filteredRow.price,
				job_document_id: jobDocumentId,
				discount: (filteredRow.discount as number) ?? 0,
			};
		});

		set(
			produce((state) => {
				if (!state.jobItemsForDocuments[jobDocumentId]) {
					state.jobItemsForDocuments[jobDocumentId] = [];
				}
				state.jobItemsForDocuments[jobDocumentId].push(...newJobItems);
			})
		);

		get().upsertJobItems(jobDocumentId, "Error pasting job items");
	},

	changeJobItem: async (jobItem: any) => {
		const {
			SELECTED_FIELD,
			interpr_de,
			interpr_fr,
			interpr_it,
			is_new,
			custom,
			expanded,
			inEdit,
			selected,
			bpo_id,
			fpo_id,
			...filteredRow
		} = jobItem;

		const jobItems = get().jobItemsForDocuments[jobItem.job_document_id];

		const newJobItems = jobItems.map((row: JobItemEntityType) =>
			row.id === filteredRow.id ? filteredRow : row
		);

		set(
			produce((state) => {
				state.jobItemsForDocuments[jobItem.job_document_id] =
					newJobItems;
			})
		);

		const { error } = await supabase
			.from(SupabaseTableEnum.JOB_ITEMS)
			.upsert([filteredRow], {
				onConflict: "id",
				defaultToNull: false,
			});

		if (error) {
			showNotification({
				message: "Fehler beim Ändern der Position",
				type: "error",
			});
			Logger.error(error);
			return;
		}
	},
});
