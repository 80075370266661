import { createContext, useContext, useEffect, useState } from "react";
import {
	ArticleEntityType,
	ArticleEntityTypeInsert,
	SupabaseTableEnum,
	TariffEntityType,
	TariffEntityTypeInsert,
} from "../../../lib/supabase/supabaseTypes";
import {
	useDatabaseFetch,
	useDatabaseFunctionsWithPromise,
} from "../hooks/useDatabase";
import { Logger } from "../../../lib/logger/Logger";
import { useToast } from "./ToastContext";
import { useNavigate } from "react-router-dom";
import { useCentralStore } from "../store/Central";

// It could be argued that tariffs and articles should have separate contexts. However, their provider would sit in the same place anyway
// so the difference is minimal. Because their logic reads the same, they are combined here.

// is_new only exists on the default tables, we add it here as this type is used for potentially processing both default and user positions
export type ExtendedTariffEntityType = TariffEntityType & {
	custom?: boolean;
	databaseId?: number;
	is_new?: boolean;
};
export type ExtendedArticleEntityType = ArticleEntityType & {
	custom?: boolean;
	databaseId?: number;
	is_new?: boolean;
};

// TODO: make user-specific tables listen to realtime changes
// TODO: create another context that provides job item groups

const JobItemsContext = createContext<{
	tariffs: ExtendedTariffEntityType[];
	tariffsLookup: { [key: string]: ExtendedTariffEntityType };
	articles: ExtendedArticleEntityType[];
	articlesLookup: { [key: string]: ExtendedArticleEntityType };
	handleAddTariff: (tariff: TariffEntityTypeInsert) => void;
	handleUpdateTariff: (tariff: ExtendedTariffEntityType) => void;
	handleAddArticle: (article: ArticleEntityTypeInsert) => void;
	handleUpdateArticle: (article: ExtendedArticleEntityType) => void;
	recognizeNewTariff: (tariff: ExtendedTariffEntityType) => void;
	recognizeNewArticle: (article: ExtendedArticleEntityType) => void;
}>({
	tariffs: [],
	tariffsLookup: {},
	articles: [],
	articlesLookup: {},
	handleAddTariff: () => {},
	handleUpdateTariff: () => {},
	handleAddArticle: () => {},
	handleUpdateArticle: () => {},
	recognizeNewTariff: () => {},
	recognizeNewArticle: () => {},
});

export const JobItemsContextProvider: React.FC<{
	children: React.ReactNode;
}> = ({ children }) => {
	const organizationId = useCentralStore((state) => state.organization?.id);
	const { showToast } = useToast();
	const navigate = useNavigate();
	const {
		insertDataWithPromise,
		updateDataWithPromise,
		deleteDataWithPromise,
	} = useDatabaseFunctionsWithPromise();

	const [tariffs, setTariffs] = useState<TariffEntityType[]>([]);
	const [tariffsLookup, setTariffsLookup] = useState<{
		[key: string]: ExtendedTariffEntityType;
	}>({});
	const [articles, setArticles] = useState<ExtendedArticleEntityType[]>([]);
	const [articlesLookup, setArticlesLookup] = useState<{
		[key: string]: ExtendedArticleEntityType;
	}>({});

	const {
		data: tariffsDefault,
		loading: tariffsDefaultLoading,
		error: tariffsDefaultError,
	} = useDatabaseFetch(SupabaseTableEnum.TARIFFS_DEFAULT);
	const {
		data: articlesDefault,
		loading: articlesDefaultLoading,
		error: articlesDefaultError,
	} = useDatabaseFetch(SupabaseTableEnum.ARTICLES_DEFAULT);
	const {
		data: tariffsUser,
		loading: tariffsUserLoading,
		error: tariffsUserError,
		setData: setTariffsUser,
	} = useDatabaseFetch(SupabaseTableEnum.TARIFFS, {
		column: "organization_id",
		value: organizationId,
	});
	const {
		data: articlesUser,
		loading: articlesUserLoading,
		error: articlesUserError,
		setData: setArticlesUser,
	} = useDatabaseFetch(SupabaseTableEnum.ARTICLES, {
		column: "organization_id",
		value: organizationId,
	});

	useEffect(() => {
		// Note: MUI data grid requires a unique id field for each row, so we set the id to the code

		// Map user-specific tariffs and articles to object for easier lookup
		const tariffsUserMap = tariffsUser?.reduce((acc, tariff) => {
			acc[tariff.code] = {
				...tariff,
				custom: true,
				id: tariff.code,
				databaseId: tariff.id,
			};
			return acc;
		}, {});
		Logger.log("JobItemsContext.tsx", "useEffect", "tariffsUserMap", {
			tariffsUserMap,
		});
		const articlesUserMap = articlesUser?.reduce((acc, article) => {
			acc[article.code] = {
				...article,
				custom: true,
				id: article.code,
				databaseId: article.id,
			};
			return acc;
		}, {});

		// Map default tariffs and articles to object for easier lookup
		const tariffsDefaultMap = tariffsDefault?.reduce((acc, tariff) => {
			acc[tariff.code] = {
				...tariff,
				custom: false,
				id: tariff.code,
				databaseId: tariff.id,
			};
			return acc;
		}, {});
		const articlesDefaultMap = articlesDefault?.reduce((acc, article) => {
			acc[article.code] = {
				...article,
				custom: false,
				id: article.code,
				databaseId: article.id,
			};
			return acc;
		}, {});

		Logger.log("JobItemsContext.tsx", "useEffect", {
			tariffsUserMap,
			articlesUserMap,
			tariffsDefaultMap,
			articlesDefaultMap,
		});

		// Merge user-specific and default tariffs and articles, with user-specific taking precedence
		const tariffsLookup = { ...tariffsDefaultMap, ...tariffsUserMap };
		const articlesLookup = { ...articlesDefaultMap, ...articlesUserMap };

		setTariffsLookup(tariffsLookup);
		setArticlesLookup(articlesLookup);
		setTariffs(Object.values(tariffsLookup));
		setArticles(Object.values(articlesLookup));
	}, [tariffsDefault, articlesDefault, tariffsUser, articlesUser]);

	const handleAddTariff = async (tariff: TariffEntityTypeInsert) => {
		const { success, data, error } = await insertDataWithPromise(
			SupabaseTableEnum.TARIFFS,
			[tariff]
		);
		if (success && data) {
			recognizeNewTariff(data[0]);
		}
	};

	const handleUpdateTariff = async (tariff: ExtendedTariffEntityType) => {
		// remove tariff.custom as it is not a column in the db
		const { custom, databaseId, is_new, ...filteredTariff } = tariff;
		const newTariff: TariffEntityType = {
			...filteredTariff,
			organization_id: organizationId as string,
		};

		// if custom is false, we need to create a new user tariff (without id)
		if (custom === false) {
			const newTariffWithoutId: TariffEntityTypeInsert = {
				...newTariff,
				id: undefined,
			};
			handleAddTariff(newTariffWithoutId);
		} else {
			// otherwise, we update the existing tariff (with id)
			const newTariffWithDatabaseId: TariffEntityType = {
				...newTariff,
				id: databaseId as number,
			};
			const { success, data, error } = await updateDataWithPromise(
				SupabaseTableEnum.TARIFFS,
				[newTariffWithDatabaseId]
			);

			if (success && data) {
				// set tariffsUser to new array with updated tariff, this will trigger useEffect to rerun the merge to tariffs
				setTariffsUser(
					tariffsUser?.map((t) =>
						t.id === tariff.databaseId ? data[0] : t
					) ?? []
				);
			}
		}
	};

	const handleAddArticle = async (article: ArticleEntityTypeInsert) => {
		const { success, data, error } = await insertDataWithPromise(
			SupabaseTableEnum.ARTICLES,
			[article]
		);
		if (success && data) {
			recognizeNewArticle(data[0]);
		}
	};

	const handleUpdateArticle = async (article: ExtendedArticleEntityType) => {
		// remove article.custom as it is not a column in the db
		const { custom, databaseId, is_new, ...filteredArticle } = article;
		const newArticle: ArticleEntityType = {
			...filteredArticle,
			organization_id: organizationId as string,
		};

		// if custom is false, we need to create a new user article (without id)
		if (custom === false) {
			const newArticleWithoutId: ArticleEntityTypeInsert = {
				...newArticle,
				id: undefined,
			};
			handleAddArticle(newArticleWithoutId);
		} else {
			// otherwise, we update the existing article (with id)
			const newArticleWithDatabaseId: ArticleEntityType = {
				...newArticle,
				id: databaseId as number,
			};
			const { success, data, error } = await updateDataWithPromise(
				SupabaseTableEnum.ARTICLES,
				[newArticleWithDatabaseId]
			);

			if (success && data) {
				// set articlesUser to new array with updated article, this will trigger useEffect to rerun the merge to articles
				setArticlesUser(
					articlesUser?.map((a) =>
						a.id === article.databaseId ? data[0] : a
					) ?? []
				);
			}
		}
	};

	const recognizeNewTariff = (tariff: ExtendedTariffEntityType) => {
		// set tariffsUser to new array with added tariff, this will trigger useEffect to rerun the merge to tariffs
		setTariffsUser([...(tariffsUser ?? []), tariff]);
	};

	const recognizeNewArticle = (article: ExtendedArticleEntityType) => {
		// set articlesUser to new array with added article, this will trigger useEffect to rerun the merge to articles
		setArticlesUser([...(articlesUser ?? []), article]);
	};

	return (
		<JobItemsContext.Provider
			value={{
				tariffs,
				tariffsLookup,
				articles,
				articlesLookup,
				handleAddTariff,
				handleUpdateTariff,
				handleAddArticle,
				handleUpdateArticle,
				recognizeNewTariff,
				recognizeNewArticle,
			}}
		>
			{children}
		</JobItemsContext.Provider>
	);
};

/**
 * - Provides all items that can be added to a job document, i.e. tariffs and articles.
 * - Handles merging the default and custom tables from supabase.
 * @example const { tariffs, articles } = useJobItemsContext();
 */
export const useJobItemsContext = () => {
	const context = useContext(JobItemsContext);
	if (!context) {
		throw new Error(
			"useJobItemsContext must be used within a JobItemsContextProvider"
		);
	}

	return context;
};
