import { AppBar } from "./layout-components/app-bar/app-bar.component";
import { HeaderBar } from "./layout-components/header-bar/header-bar.component";
import "./styles.css";
import { Toaster } from "../components/ui/sonner";
import { Loading } from "@/components/src/animations/loading";
import { useEffect, useState } from "react";
import { useCentralStore } from "../store/Central";
import { useRealtimeStore } from "@/lib/store/Realtime";
import {
	ActivityEntityType,
	ActivityTypeEnum,
} from "@/lib/supabase/supabaseTypes";
import { showNotification } from "../store/Central/selectors";
import { MFADialog } from "../components/mfa/mfa-dialog.component";
import { Alert, Button } from "@mui/material";
import { EnterTotp } from "../pages/auth/enter-totp.page";
import { useNavigate } from "react-router-dom";
import IntercomSnippet from "../components/intercom";
import { usePdfTemplateConfigurationContext } from "../context/PdfTemplateConfigurationContext";

export const Layout: React.FC<{
	children: React.ReactNode;
}> = ({ children }) => {
	const {
		connectRelationshipId,
		loading,
		mfaDialogOpen,
		setMFADialog,
		mfaEnrolled,
		mfaAuthenticated,
		mfaVerified,
		clientId,
		setLogoPath,
		getLogoUrl,
		analyticsEnabled,
		userId,
		organizationId,
	} = useCentralStore((state) => ({
		connectRelationshipId: state.connectRelationshipId,
		loading: !state.initialized,
		mfaDialogOpen: state.mfaDialogOpen,
		setMFADialog: state.setMFADialog,
		mfaVerified: state.mfaVerified,
		mfaAuthenticated: state.mfaAuthenticated,
		mfaEnrolled: state.mfaEnrolled,
		clientId: state.client?.id,
		setLogoPath: state.setLogoPath,
		getLogoUrl: state.getLogoUrl,
		analyticsEnabled: state.organization?.is_analytics,
		userId: state.userId,
		organizationId: state.organization?.id,
	}));
	const { pdfTemplateConfiguration } = usePdfTemplateConfigurationContext();
	const navigate = useNavigate();
	const [last, setLast] = useState<ActivityEntityType | null>(null);

	const { subscribe, unsubscribe, activityId, activities } = useRealtimeStore(
		(state) => ({
			subscribe: state.subscribe,
			unsubscribe: state.unsubscribe,
			activityId: state.activityId,
			activities: Object.values(state.activitiesLookup).sort((a, b) => {
				if (a.last_reply_at && b.last_reply_at)
					return a.last_reply_at < b.last_reply_at ? 1 : -1;
				else return -1;
			}),
		})
	);

	useEffect(() => {
		if (!mfaAuthenticated) return;
		if (!clientId) return;
		const lastActivity = activities[0];
		if (!lastActivity || last?.id == lastActivity.id) return;

		// TODO: make sure the job was shared by the dentist
		if (lastActivity.activity_type == ActivityTypeEnum.SHARED_JOB_REQUEST) {
			showNotification({
				message: "Neuer Auftrag eingegangen.",
				type: "info",
				action: {
					label: "Anfrage öffnen",
					onClick: () =>
						navigate(
							`/client/${clientId}/request/${lastActivity.job_request_id}`
						),
				},
			});
			setLast(lastActivity);
		}
	}, [activities, mfaAuthenticated, last?.id, clientId, navigate]);

	useEffect(() => {
		if (!pdfTemplateConfiguration?.general.logoPath) return;
		setLogoPath(pdfTemplateConfiguration?.general.logoPath);
		getLogoUrl();
	}, [pdfTemplateConfiguration?.general]);

	useEffect(() => {
		if (!mfaAuthenticated) return;
		if (connectRelationshipId) subscribe(connectRelationshipId);

		return () => {
			unsubscribe();
		};
	}, [connectRelationshipId, activityId, mfaVerified, mfaAuthenticated]);

	useEffect(() => {
		if (!analyticsEnabled) return;
		const script = document.createElement("script");
		script.type = "text/javascript";
		script.async = true;
		script.innerHTML = `
			(function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "o9tqxp6neg");
		`;
		document.head.appendChild(script);

		return () => {
			document.head.removeChild(script);
		};
	}, [analyticsEnabled]);

	if (mfaVerified && !mfaAuthenticated) {
		return <EnterTotp />;
	}

	if (loading || !mfaVerified) {
		return <Loading height="100vh" width="100vw" />;
	}
	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				backgroundColor: "var(--primary-main)",
			}}
		>
			<div className="bg-white text-xs">
				{(import.meta.env.MODE === "development" ||
					import.meta.env.MODE === "development_testing") &&
					`User: ${userId} Org: ${organizationId}`}
			</div>
			<IntercomSnippet />
			<HeaderBar />
			<div
				style={{
					display: "flex",
					flexDirection: "row",
				}}
			>
				<AppBar />
				<div className="layout-children">
					{mfaEnrolled === false && (
						<div className="w-full">
							<Alert
								className="w-full"
								severity="warning"
								action={
									<Button
										onClick={() => {
											setMFADialog(true);
										}}
									>
										<span className="w-80 whitespace-nowrap overflow-hidden text-ellipsis">
											Mehrfaktor-Authentifizierung
											einrichten
										</span>
									</Button>
								}
							>
								<span className="whitespace-nowrap w-1/2 overflow-ellipsis ">
									Die Mehrfaktor-Authentifizierung ist für Ihr
									Konto nicht eingerichtet. Bitte richten Sie
									sie jetzt ein!
								</span>
							</Alert>
						</div>
					)}
					{children}
					<Toaster />
				</div>
			</div>
			<MFADialog open={mfaDialogOpen} onOpen={setMFADialog} />
		</div>
	);
};
